﻿import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

ScrollTrigger.matchMedia({

    "(min-width: 768px)": function () {
        
        const sections = gsap.utils.toArray("section");
        var refreshPriority = sections.length;
        sections.forEach(container => {
            if (container.classList.contains("slides-pin")) {
                const tl = gsap.timeline();
                const slides = gsap.utils.toArray(container.querySelectorAll(".scroll-slide"));
                const slidesText = gsap.utils.toArray(container.querySelectorAll(".slides-text"));
                var i = 0;
                slides.forEach(slide => {
                    if (slides[0] !== slide) {
                        tl.set(slide,
                            {
                                yPercent: 100
                            });

                        tl.set(slidesText[i],
                            {
                                opacity: 0
                            });

                        tl.to(slide,
                            {
                                duration: 2,
                                yPercent: 0
                            });

                        tl.to(slidesText[i - 1],
                            {
                                duration: 2,
                                opacity: 0
                            },
                            "-=2");

                        tl.to(slidesText[i],
                            {
                                duration: 2,
                                opacity: 1,
                                zIndex: 10
                            },
                            "-=1");
                    } else {
                        tl.set(slidesText[i],
                            {
                                opacity: 1
                            });
                    }
                    i++;
                });

                ScrollTrigger.create({
                    animation: tl,
                    trigger: container,
                    start: "top",
                    end: "bottom",
                    scrub: 2,
                    pin: true,
                    snap: 1,
                    refreshPriority: refreshPriority
                });
            }
            refreshPriority--;
        });
        ScrollTrigger.sort();
    }
});